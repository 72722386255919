import * as React from "react";
import { useTranslation } from "react-i18next";

import pageStyles from "./styles/RolesInfoStyles";
import ArtistIcon from "assets/dynamics/artist";
import ViewerIcon from "assets/dynamics/viewer";
import CommerceImg from "assets/images/commerce.png";
import Model from "hooks/Model";
import { useNavigate } from "react-router-dom";

const RolesInfoScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const styles = pageStyles();

  return (
    <>
      <p className={styles.label}>{t("pages.home.rolesInfo.label")}</p>
      <div className={styles.infoContainer}>
        <div className={styles.columnInfo}>
          <div className={styles.cardRole}>
            <div className={styles.containerIcon}>
              <ViewerIcon />
            </div>
            <div className={styles.rowInfo}>
              <h3 className={styles.titleRole}>
                {t("pages.home.rolesInfo.labelViewer")}
              </h3>
              <p className={styles.descriptionRole}>
                {t("pages.home.rolesInfo.descriptionViewer")}
              </p>
              <button className={styles.buttonRole} onClick={() => Model.setData("showAuth", true)}>
                {t("pages.home.rolesInfo.join")}
              </button>
            </div>
          </div>
        </div>
        <div className={styles.columnInfo}>
          <div className={styles.cardRole}>
            <div className={styles.containerIcon}>
              <ArtistIcon />
            </div>
            <div className={styles.rowInfo}>
              <h3 className={styles.titleRole}>
                {t("pages.home.rolesInfo.labelArtist")}
              </h3>
              <p className={styles.descriptionRole}>
                {t("pages.home.rolesInfo.descriptionArtist")}
              </p>
              <button className={styles.buttonRole} onClick={() => navigate('/legal?document=artist')}>
                Conocer más
              </button>
            </div>
          </div>
        </div>
        <div className={styles.columnInfo}>
          <div className={styles.cardRole}>
            <div className={styles.containerIcon}>
              <img alt="commerce" src={CommerceImg} className={styles.imageIcon} />
            </div>
            <div className={styles.rowInfo}>
              <h3 className={styles.titleRole}>
                Comercio aliado
              </h3>
              <p className={styles.descriptionRole}>
                {"Recibe pagos en $FROIN y libérate de las plataformas convencionales. No importa el tipo de negocio, solo debes postularte enviando un correo a help@froin.co y nuestro equipo te evaluará, posteriormente se te daría el paso a paso para recibir pagos en $FROIN y que puedas convertirlos a tu moneda local.\n\n¿Te parece buena idea recibir nueva clientela sin que debas invertir en publicidad? Acepta ser parte de nuestra comunidad y recibe los beneficios que nuestra empresa tiene para ti."}
              </p>
              <button className={styles.buttonRole} onClick={() => {
                window.location.href = "mailto:help@froin.co";
              }}>
                Enviar correo
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RolesInfoScreen;
