import * as React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import { databaseService, firestoreService } from "utils/firebase";
import { Layout, SuggestedVideos, ElementsParallax } from "components";
import FeaturesHome from "./FeaturesHome";
import BannerHome from "./BannerHome";
import RolesInfo from "./RolesInfo";
import InfoBanner from "./InfoBanner";
import Model from "hooks/Model";

function HomeScreen() {
  const styles = pageStyles();
  const [suggestedVideos, setSuggestedVideos] = useState([]);

  const firstLoad = async () => {
    try {
      Model.setData("loading", true);

      const suggestedVideosResponse = (
        await databaseService.getDatabase("settings/suggestedVideos")
      ).val();

      if (suggestedVideosResponse.length > 0) {
        const allPromises = suggestedVideosResponse.map((id) =>
          firestoreService.getDoc("videos", id)
        );

        const allVideos = (await Promise.all(allPromises)).map((value) => ({
          ...(value?.data() || {}),
          id: value?.id,
        }));

        setSuggestedVideos(allVideos);
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      Model.setData("loading", false);
    }
  };

  useEffect(() => {
    firstLoad();
  }, []);

  return (
    <Layout withBg>
      <ElementsParallax variant="leftBg" />
      <ElementsParallax variant="rightBg" />
      <div className={styles.container}>
        <InfoBanner />
        <BannerHome />
        <SuggestedVideos
          title='🤑 Gana mientras apoyas con tus views'
          data={suggestedVideos}
        />
        <RolesInfo />
        <FeaturesHome />
      </div>
    </Layout>
  );
}

export default HomeScreen;

const pageStyles = makeStyles(() => ({
  leftBg: {
    position: "fixed",
    zIndex: 0,
    left: 0,
  },
  rightBg: {
    position: "fixed",
    zIndex: 0,
    right: 0,
  },
  container: {
    position: "relative",
    zIndex: 1,
  },
}));
