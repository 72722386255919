import * as React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import { databaseService } from "utils/firebase";
import Model from "hooks/Model";
import { colors, Commons } from "styleguide";

interface HomeBannerProps {
  title?: string;
  description?: string;
  show?: boolean;
}

function InfoBanner() {
  const styles = pageStyles();
  const [homeBanner, setHomeBanner] = useState<HomeBannerProps>();

  const firstLoad = async () => {
    try {
      Model.setData("loading", true);

      const homeBannerResponse = (
        await databaseService.getDatabase("settings/homeBanner")
      ).val();

      if (homeBannerResponse.show) {
        setHomeBanner(homeBannerResponse)
      } else {
        setHomeBanner({})
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      Model.setData("loading", false);
    }
  };

  useEffect(() => {
    firstLoad();
  }, []);

  if (homeBanner?.title) {
    return (
      <p className={styles.textNewsBanner}><b>{homeBanner.title}</b><br/>{homeBanner.description?.replace(/\\n/g, '\n')}</p>
    );
  }

    return <></>
}

export default InfoBanner;

const pageStyles = makeStyles(() => ({
  textNewsBanner: {
    backgroundColor: colors.yellow,
    color: colors.black,
    boxShadow: colors.shadowWhite,
    padding: '12px 24px',
    fontSize: 24,
    borderRadius: 18,
    marginBottom: 30,
    whiteSpace: 'break-spaces',
    [Commons.smallQuery]: {
      fontSize: 18,
      padding: '6px 12px',
    }
  },
}));