import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  bannerContainer: {
    ...Commons.flexRow,
    backgroundImage: `linear-gradient(144deg, ${colors.black} 30%, ${colors.orange})`,
    minHeight: "calc(100vh - 300px)",
    width: "100%",
    borderRadius: 24,
    boxShadow: `0 0 60px 0px ${colors.white}50`,
    marginBottom: 60,
    animation: "$neonBgLoopWhite 3s linear infinite",
    boxSizing: "border-box",
    [Commons.smallQuery]: {
      flexDirection: "column",
      height: "unset",
      padding: 30,
    },
  },
  logo: {
    height: 72,
    position: 'relative',
    display: 'block',
    margin: '60px auto',
    [Commons.smallQuery]: {
      height: 36
    }
  },
  "@keyframes neonBgLoopWhite": {
    "0%": {
      boxShadow: `0 0 50px 3px ${colors.white}30`,
    },
    "50%": {
      boxShadow: `0 0 50px 3px ${colors.white}75`,
    },
    "100%": {
      boxShadow: `0 0 50px 3px ${colors.white}30`,
    },
  },
});

export default styles;
