import { useContext, useEffect, useState } from "react";

import { databaseService } from "utils/firebase";
import { Store } from "hooks/main_store";
import Model from "hooks/Model";
import Api from "utils/api";
import { returnApiError } from "utils/validations";

interface RankingServiceProps {
  channel?: string;
}

interface RankingProps {
  finished: boolean;
  finalDate: string;
  prize: number;
  accumulated: number;
  terms: string;
  infoBanner: {
    title: string;
    description: number;
    show: boolean;
  };
}

const RankingService = ({channel}: RankingServiceProps) => {
  const { state } = useContext(Store);

  const [listRanking, setListRanking] = useState<string[]>([]);
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  const [currentScore, setCurrentScore] = useState<number>(0);
  const [settings, setSettings] = useState<RankingProps>();
  const [loading, setLoading] = useState(true);

  const getStatsRanking = async () => {
    const response = await Api.post("/ranking/vwin/getStatsRanking");

    if (response.ok) {
      setListRanking((response?.data as any)?.list || [])
      setCurrentPosition((response?.data as any)?.position || 0)
    } else {
      throw new Error(returnApiError(response));
    }
  };

  const getMyCurrentScore = async () => {
      if (state.infoUser?.uid) {
        const currentScoreValue: any = (
          (await databaseService.getDatabase(`ranking/${state.infoUser?.uid}`)).val()
        ) as unknown as number;

        setCurrentScore(currentScoreValue?.score || 0);
      }
  };

  const getSettings = async () => {
    const currentSettings = (
      (await databaseService.getDatabase("ranking/settings")).val()
    ) as unknown as RankingProps;

    setSettings(currentSettings);
};

  const firstLoad = async () => {
    try {
      Model.setData("loading", true);
      setLoading(true)
      if (channel) {
        
      } else if (state.infoUser?.uid) {
        await getMyCurrentScore();
        await getSettings();
        await getStatsRanking()
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      setLoading(false)
    }
  };


  useEffect(() => {
    firstLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, state.infoUser?.uid]);

  return {
    currentScore,
    settings,
    loading,
    currentPosition,
    listRanking,
  }
}

export default RankingService;
