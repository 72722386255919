import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  label: {
    color: colors.white,
    fontSize: 42,
    fontWeight: 600,
    margin: "90px 0 30px 0",
    [Commons.smallQuery]: {
      fontSize: 30,
      textAlign: 'center',
    },
  },
  infoContainer: {
    ...Commons.flexColumn,
    gap: 30,
    marginBottom: 60,
    justifyContent: "center",
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  columnInfo: {
    width: '100%',
    [Commons.smallQuery]: {
      flex: "unset",
      width: "100%",
      maxWidth: "unset",
    },
  },
  cardRole: {
    ...Commons.flexRow,
    alignItems: "center",
    borderRadius: 25,
    overflow: "hidden",
    padding: 36,
    boxShadow: colors.shadowPurlpeInset,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    [Commons.smallQuery]: {
      padding: 12,
      flexDirection: 'column'
    },
  },
  titleRole: {
    color: colors.white,
    textAlign: "left",
    fontWeight: 600,
    fontSize: 42,
    margin: '12px 0',
    [Commons.smallQuery]: {
      fontSize: 30,
      textAlign: "center",
    },
  },
  rowInfo: {
    ...Commons.flexColumn,
    alignItems: "flex-start",
    marginLeft: 30,
    [Commons.smallQuery]: {
     alignItems: "center",
      marginLeft: 0,
    },
  },
  containerIcon: {
    ...Commons.flexCenter,
    minWidth: 300,
    [Commons.smallQuery]: {
      height: "unset",
      minWidth: 'unset',
      marginTop: 30
    },
  },
  imageIcon: {
    height: 'auto',
    width: 300
  },
  descriptionRole: {
    color: colors.white,
    fontSize: 18,
    whiteSpace: "break-spaces",
    textAlign: 'left',
    margin: 0,
    [Commons.smallQuery]: {
      textAlign: "center",
      fontSize: 16,
      padding: "0 18px",
    },
  },
  buttonRole: {
    ...Commons.defaultButton,
    backgroundColor: colors.orange,
    color: colors.white,
    padding: "12px 36px",
    borderRadius: 24,
    fontSize: 24,
    fontWeight: "bold",
    margin: "24px 0 0 0",
    transition: "all 0.2s",
    "&:hover": {
      boxShadow: colors.shadowWhite,
    },
    [Commons.smallQuery]: {
      margin: "24px 0 30px 0",
    },
  },
  "@keyframes neonBgLoop": {
    "0%": {
      boxShadow: `inset 0 0 50px 3px ${colors.secondary}50`,
    },
    "50%": {
      boxShadow: `inset 0 0 50px 3px ${colors.secondary}`,
    },
    "100%": {
      boxShadow: `inset 0 0 50px 3px ${colors.secondary}50`,
    },
  },
});

export default styles;
