import * as React from "react";
import { useContext } from "react";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import LogoFroin from "assets/logos/LogoFroinBlanco.png";
import { ElementsParallax, Layout } from "components";
import pageStyles from "./styles/welcomeStyles";
import { Store } from "hooks/main_store";
import Model from "hooks/Model";

const Welcome = () => {
  const { state: { loading: globalLoading }, } = useContext(Store);
  
  // const { t } = useTranslation();
  const styles = pageStyles();
  const [numPages, setNumPages] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    Model.setData("loading", false);
    Model.setData("opacity", 0.7);

  }, []);

  return (
    <Layout withBg noheader noFooter>
      <ElementsParallax variant="leftBg" />
      <ElementsParallax variant="rightBg" />
      <img alt="FROIN" src={LogoFroin} className={styles.logo} />
      <div className={styles.bannerContainer}>
      </div>
    </Layout>
  );
};

export default Welcome;
